import React from 'react';
import "./Empleo.scss"
import { Link } from 'react-router-dom';

const Empleo = ({ datos }) => {
    return (
        <div className="row work-item d-flex flex-column border-bottom p-3">
            <div className="col-12 justify-content-center align-items-center">
                <p>{datos.empresa}</p>
            </div>
            <div className='col-12'>
                <div  className='row d-flex ml-1'>
                    <div className="col-8 work-item-details align-items-start p-2">
                        <h6>{datos.nombre_puesto}</h6>
                    </div>
                    <div className="col-4 work-actions align-items-center">
                        {" "}
                        <Link
                            className="btn"
                            to="/bolsa-de-trabajo"
                        >
                            Ver más
                        </Link>
                    </div>
                </div>
                
            </div>
           
        </div>
    );
};

export default Empleo;