import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal, Table } from "react-bootstrap";
import * as moment from "moment";
//Redux
import { connect } from "react-redux";
import rankIcon from "../../../static/icons/tag-icon.png";
import "./TablaCalificaciones.scss";
import { useParams } from "react-router-dom";
import { obtenerPuntosAdicionales } from "../../../actions/cursosActions";
import DetallePuntosModal from "./DetallePuntosModal";

const TablaCalificaciones = (props) => {
	const { calificaciones } = props;


	return (
		<div className="tabla-calificaciones table-responsive">
			<Table className="tabla">
				<thead>
					<tr>
						<th>Módulo</th>
						<th>Sesión</th>
						<th>Fecha</th>
						<th>Nota/Quiz</th>
						<th>
							<img src={rankIcon} style={{ maxWidth: "30px" }} />
						</th>
						<th>Asistencia</th>
					</tr>
				</thead>
				<FilasTabla calificaciones={calificaciones} />
			</Table>
		</div>
	);
};

const mapStateToProps = (state) => ({
	calificaciones: state.cursos.calificaciones,
});
export default connect(mapStateToProps, {})(TablaCalificaciones);

const FilasTabla = (props) => {
	const { calificaciones } = props;
	const { idCapacitacion } = useParams();
	const [totalPuntos, setTotalPuntos] = useState(0);
	const [showModal, setShowModal] = useState(false);
	const [puntosAdicionales, setPuntosAdicionales] = useState([]);

	const { usuario } = useSelector(state => state.usuario);
	const dispatch = useDispatch();
	//console.log(usuario);

	const handleDetallePuntosAdicionales = () => {
		setShowModal(true)
	};

	const handleCloseModal = () => {
        setShowModal(false);
    };

	useEffect(() => {
		const fetchPuntosAdicionales = async () => {
			const puntosAd = await dispatch(obtenerPuntosAdicionales(usuario.id_estudiante));
			setPuntosAdicionales(puntosAd);
		};
		fetchPuntosAdicionales();
	}, []);

	useEffect(() => {
		if (calificaciones.detalles) {
			let puntosAcumulados = 0;
			calificaciones.detalles.map((sesion) => {
				sesion.children.map((child) => {
					if (child.puntos) {
						puntosAcumulados += parseInt(child.puntos);
					}
				});
			});
			setTotalPuntos(puntosAcumulados);
		}

	}, [calificaciones]);

	return (
		<tbody>

			{calificaciones.detalles &&
				calificaciones.detalles.map((fila, index) => (
					<React.Fragment key={index}>
						{fila.children.map((sesion, index) => (
							<React.Fragment key={index}>
								{index < 1 ? (
									<tr key={fila.id}
										className={
											sesion.promediable === "1" || sesion.id_tipo_sesion == 2
												? "font-weight-bold"
												: ""
										}>
										<td
											rowSpan={fila.children.length}
											title={fila.nombre}
											className="td-modulo position-relative"
										>
											<div className="content-nombre-modulo w-100 h-100 position-absolute d-flex align-items-center font-weight-bold">
												<p>
													{fila.nombre.length > 50
														? `${fila.nombre.substr(
															0,
															50
														)}...`
														: fila.nombre}
												</p>
											</div>
										</td>
										<td
											className="td-nombre-sesion"
											title={sesion.nombre}

										>
											{sesion.nombre}
										</td>
										<td>
											{moment(sesion.fecha).format(
												"DD/MM/YYYY"
											)}
										</td>
										<td className="text-center">
											{parseInt(sesion.nota)}
										</td>
										<td className="text-center">
											{parseInt(sesion.puntos)}

										</td>
										<td>{sesion.asistencia}</td>
									</tr>
								) : (
									<tr key={fila.id}
										className={
											sesion.promediable === "1" || sesion.id_tipo_sesion == 2
												? "font-weight-bold"
												: ""
										}>
										<td title={sesion.nombre} >
											{sesion.nombre}
										</td>
										<td>
											{moment(sesion.fecha).format(
												"DD/MM/YYYY"
											)}
										</td>
										<td className="text-center">
											{parseInt(sesion.nota)}
										</td>
										<td className="text-center">
											{parseInt(sesion.puntos)}
										</td>
										<td>{sesion.asistencia}</td>
									</tr>
								)}
							</React.Fragment>
						))}
					</React.Fragment>

				))}


			<tr>
				<td></td>
				<td></td>
				<td className="text-right"><strong>Total</strong></td>
				<td></td>
				<td className="text-center">{totalPuntos}</td>
				<td></td>


			</tr>


			{/*	
			{(parseInt(idCapacitacion) >= 45 &&
				parseInt(idCapacitacion) !== 100) ||
				parseInt(idCapacitacion) === 31 ||
				parseInt(idCapacitacion) === 32 ||
				parseInt(idCapacitacion) === 35 ? (
				<tr>
					<td
						colSpan="3"
						style={styles.notaFinal}
						className="font-weight-bold text-right"
					>
						Promedio Modular
					</td>
					<td
						colSpan="3"
						style={styles.notaFinal}
						className="font-weight-bold"
					>
						{calificaciones.promedio_modular
							? parseInt(calificaciones.promedio_modular)
							: 0}
					</td>
				</tr>
			) : null}
			<tr>
				<td
					colSpan="3"
					style={styles.notaFinal}
					className="font-weight-bold text-right"
				>
					{(parseInt(idCapacitacion) >= 45 &&
						parseInt(idCapacitacion) !== 100) ||
						parseInt(idCapacitacion) === 31 ||
						parseInt(idCapacitacion) === 32 ||
						parseInt(idCapacitacion) === 35
						? "TAF"
						: "Evaluación Final"}
				</td>
				<td
					colSpan="3"
					style={styles.notaFinal}
					className="font-weight-bold"
				>
					{calificaciones.examen
						? parseInt(calificaciones.examen)
						: 0}
				</td>
			</tr>*/}
			{(parseInt(idCapacitacion) >= 45 &&
				parseInt(idCapacitacion) !== 100) ||
				parseInt(idCapacitacion) === 31 ||
				parseInt(idCapacitacion) === 32 ||
				parseInt(idCapacitacion) === 35 ? (
				<>
					<tr>
						<td
							colSpan="3"
							style={styles.notaFinal}
							className="font-weight-bold text-right align-middle"
						>
							
							Puntos Adicionales {(idCapacitacion == 148 || idCapacitacion == 154 || idCapacitacion == 153) && "foros, comentarios, completar perfil, etc"
							}
						</td>
						<td
							colSpan="3"
							style={styles.notaFinal}
							className="font-weight-bold align-middle"
						>
							{calificaciones.puntos_adicionales}
							{(idCapacitacion != 148 && idCapacitacion != 154 && idCapacitacion != 153)&&<button className="btn button-detalle ml-3" onClick={handleDetallePuntosAdicionales}> Ver detalle</button>}
						</td>

					</tr>
					<tr>
						<td
							colSpan="3"
							style={styles.notaFinal}
							className="font-weight-bold text-right"
						>
							Calificacion final
						</td>
						<td
							colSpan="3"
							style={styles.notaFinal}
							className="font-weight-bold"
						>
							{calificaciones.promedio
								? Math.round(calificaciones.promedio)
								: 0}
						</td>

					</tr>
				</>
			) : null}

			<Modal show={showModal} onHide={handleCloseModal}>
				<Modal.Header closeButton>
					<Modal.Title>Detalle de Puntos Adicionales</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<DetallePuntosModal id_usuario={usuario.id_usuario} />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseModal}>
						Cerrar
					</Button>
				</Modal.Footer>
			</Modal>
		</tbody>
	);
};
const styles = {
	notaFinal: {
		fontSize: "14px",
	},
};
