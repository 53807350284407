import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ContenidoSesiones from '../components/ContenidoSesiones/ContenidoSesiones';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Mensajeria from '../components/mensajeria/Mensajeria';
import ChangePass from '../components/seguridad/ChangePass';
import ToastMessage from '../components/Toast';
import ContenidoEvento from '../pages/ContenidoEvento/ContenidoEvento';
import InformacionInstitucional from '../pages/InformacionInstitucional';
import Page404 from '../pages/Page404';
import PerfilUsuario from '../pages/PerfilUsuario';
import ReglasNormas from '../pages/ReglasNormas';
import SesionesModulos from '../pages/SesionesModulos/SesionesModulos';
import Proyectos from '../components/Proyectos/Proyectos';
import Examen from "../pages/Examen/Examen"
import ExamenPreview from "../pages/Examen/ExamenPre"
import Resultado from "../pages/Examen/ResultadoExamen"
import TemaForo from "../pages/TemaForo/TemaForo"
import { useSelector } from 'react-redux';
import Videos from '../pages/VideosProyecto/Videos';
import BolsaDeTrabajo from '../pages/BolsaDeTrabajo/BolsaDeTrabajo';
import PodCasts from '../pages/PodCasts/PodCasts';
import ReporteDePago from '../pages/ReporteDePago/ReporteDePago';
import Ruleta from '../pages/Ruleta/ruleta';
import Ruleta2 from '../pages/Ruleta/ruleta2';
import Premio from '../components/Ruleta/premio';
import ReviewComponent from '../components/Proyectos/ReviewComponent/ReviewComponent';


const DashboardRouter = () => {

  const { logueado } = useSelector(state => state.usuario);

  if(logueado === null) return null;

  return (
    <>
      <Header />
      <div style={{
        position: 'relative',
        zIndex: '10'
      }}>
        <div  style={{
            position: 'absolute',
            top: '5.5rem',
            right:'2rem'
          }}>
      
            {/*<ToastMessage />*/}
        </div>
      </div>
      <div className='container-fluid' style={{paddingBottom:'50px',overflow:'hidden'}}>
        <Switch>
          <Route exact path="/perfil" component={PerfilUsuario} />
          <Route exact path="/bolsa-de-trabajo" component={BolsaDeTrabajo} />
          <Route exact path="/reportar-pago/:idPension" component={ReporteDePago} />
          <Route exact path="/listen-podcasts" component={PodCasts} />
          <Route exact path="/capacitaciones/:idCapacitacion/modulos" component={ContenidoEvento}/>
          <Route exact path="/capacitaciones/:idCapacitacion/foro/tema/:tema" component={TemaForo}/>
          <Route exact path="/capacitaciones/:idCurso/sesiones/:idSesion/videos/:video" component={Videos}/>
          <Route exact path="/capacitaciones/:idCurso/sesiones" component={SesionesModulos}/>
          <Route exact path="/capacitaciones/:idCurso/sesiones/:idSesion" component={ContenidoSesiones} />
          <Route exact path="/capacitaciones/:idCurso/sesiones/:idSesion/actividad/resolver" component={Examen} />
          <Route exact path="/capacitaciones/:idCurso/sesiones/:idSesion/actividad" component={ExamenPreview} />
          <Route exact path="/capacitaciones/:idCurso/sesiones/:idSesion/resultado" component={Resultado} />
          <Route exact path="/capacitaciones" component={Proyectos} />
          <Route exact path="/mensajeria" component={Mensajeria} />
          <Route exact path="/cuenta" component={ChangePass} />
          <Route exact path="/reglas-normas" component={ReglasNormas} />
          <Route exact path="/institucion" component={InformacionInstitucional} />
          <Route exact path="/ruleta" component={Ruleta2} />
          <Route exact path="/review" component={ReviewComponent} />


          <Route path="*" component={Page404} />
        </Switch>
      </div>
      <Footer />
    </>
  );
}
export default DashboardRouter;