import React, { useEffect, useState } from 'react';
import Layout from '../../Layout/Layout';
import './ReviewComponent.scss';
import { CSSTransition } from 'react-transition-group';

const ReviewComponent = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [inProp, setInProp] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 770);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        // Trigger the animation when the component mounts
        setInProp(true);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <CSSTransition in={inProp} timeout={300} classNames="fade" unmountOnExit>
            <Layout>
                <div className="background-container-review"></div>
                <div className='container-review'>
                    <div className='contenido-review'>
                        <div className='review-title'>
                            <h1>¡COMPARTE TU EXPERIENCIA EN FACEBOOK Y OBTÉN 30 PUNTOS!</h1>
                        </div>
                        <div className='subtitle-review'>
                            <img src="/images/icono-usuario.png" alt="Review" />
                            <p>Solo sigue estos sencillos pasos para ganar tus puntos:</p>
                        </div>
                        <div>
                            <div className='review-step'>
                                <h3>1. Valora nuestra página en Facebook</h3>
                                <p> {!isMobile ? 'Haz click en ir a Facebook' : "Presiona el logo de Facebook"}, y deja tu comentario sobre tu experiencia en INEDI Posgrados</p>
                            </div>
                            <div className='review-step'>
                                <h3>2. Envia una captura por WhatsApp</h3>
                                <p>Toma una captura y enviala con tu nombre {!isMobile ?'haciendo clic en ir a Whatsapp': "presionando el logo de WhatsApp"}</p>
                            </div>
                        </div>
                        <div className='review-buttons'>
                            <a href="https://www.facebook.com/inediposgradosperu/reviews" target="_blank" className='facebook-button'>
                                <img src="/images/login-sociales/fb-white.png" alt="Facebook" />
                                <p className='review-text'>Ir a Facebook</p>
                            </a>
                            <a href="https://wa.link/rmjr34" target="_blank" className='whatsapp-button'>
                                <img src="/images/login-sociales/ws-white.png" alt="Facebook" />
                                <p className='review-text'>Ir a Whatsapp</p>
                            </a>
                        </div>
                    </div>
                </div>
            </Layout>
        </CSSTransition>
    );
};

export default ReviewComponent;