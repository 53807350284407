import React, { useEffect, useState } from "react";
import './PodCasts.css'
import FloatingWhatsAppButton from "../../components/WhatsappButton/FloatingWhatsAppButton";

//Redux
import { connect } from "react-redux";
import { listarPodCasts } from "../../actions/cursosActions";
import Layout from "../../components/Layout/Layout";

import ListaDePodCasts from "./ListaDePodCasts";
import StickPlayer from "./StickPlayer";
import { CSSTransition } from "react-transition-group";
//import { showEncuesta } from "../../actions/encuestaActions";
const PodCasts = (props) => {


  //const show = useSelector(state => state.encuesta.show);
  //state
  const [datosPodcasts,setDatosPodCasts] = useState()
  const { detalleNivel} = props;
  //Funciones
  const { listarPodCasts } = props;
  const [inProp, setInProp] = useState(false);
  

  useEffect(()=>{
    listarPodCasts(detalleNivel[0].id_estudiante).then((resp)=>{
        setDatosPodCasts(resp)
    })

    // Trigger the animation when the component mounts
    setInProp(true);
  },[])

  return (
    <CSSTransition in={inProp && datosPodcasts} timeout={300} classNames="fade" unmountOnExit>
    <Layout>

      <div className="row" style={{overflow:'hidden'}}>
          <div className="cabecera_podcast w-100">
            <div className="container">
              <div className="row">
                <div className="col-12 py-5" style={{color:'#fff'}}>
                  <h1>PodCasts</h1>
                  <p>Aprende en tu tiempo libre</p>
                </div>
              </div>
            </div>        
          </div>
        </div>
      <div className="container" style={{marginTop:'40px'}}>
    
        <div className="row">
            
                <ListaDePodCasts podcasts={datosPodcasts?.podcasts} />
        
        </div>

      </div>
      <StickPlayer />
      <FloatingWhatsAppButton />
    </Layout>
    </CSSTransition>
  );
};

const mapStateToProps = (state) => ({
  detalleNivel: state.usuario.detalleNivel,
});


export default connect(mapStateToProps,{listarPodCasts})(PodCasts);