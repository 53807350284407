import React, { useEffect, useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { obtenerDetallesPuntajeAdicional } from '../../../actions/cursosActions';

const DetallePuntosModal = ({ id_usuario }) => {
    const [puntajesAdicionales, setPuntajesAdicionales] = useState([]);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(obtenerDetallesPuntajeAdicional(id_usuario)).then((res) => {
            setPuntajesAdicionales(res);
            setLoading(false);
        });
    }, []);
    //console.log(puntajesAdicionales);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                <Spinner animation="border" />
            </div>
        );
    }
    return (
        <div>
            {puntajesAdicionales && !loading ? (
                <div>
                    {/* Renderiza los puntajes adicionales aquí */}
                    {puntajesAdicionales.map((puntaje, index) => (
                        <div key={index}>
                            <strong>{puntaje.tipo_puntaje_descripcion}:</strong> {puntaje.puntaje_total}
                        </div>
                    ))}
                </div>
            ) : (
                <div>No hay puntajes adicionales.</div>
            )}
        </div>
    );
};


export default DetallePuntosModal;