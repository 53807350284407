import React, { useEffect,useState } from 'react';
import Layout from '../Layout/Layout';
import { useSelector } from 'react-redux';
import './cupon.css';
import { Spinner } from 'react-bootstrap';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';


const Cupon = ({ premio, tipo_premio }) => {
    //console.log(premio[0]);
    //console.log(tipo_premio);
    const { cupon } = useSelector(state => state.cursos);
    const { usuario } = useSelector(state => state.usuario);
    const [loading, setLoading] = useState(true);

    //Para copiar el cupon
    const handleCopy = () => {
        navigator.clipboard.writeText(cupon)
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'Código copiado al portapapeles',
                    showConfirmButton: false,
                    timer: 1500
                });
            })
            .catch(err => {
                console.error('Error al copiar el código:', err);
            });
    };

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    return (
        <Layout>
            { loading ? <Spinner animation="border"/> 
                : premio && tipo_premio ?
                    <div className="container-cupon">
                        {tipo_premio === 2||tipo_premio === 4 ?
                            <h4>Felicidades, {usuario.nombre_usuario} <span className="highlight">ganaste</span> el <span className="highlight">acceso a cursos en vivo</span></h4>
                            : tipo_premio === 1 ? <h4>Felicidades, {usuario.nombre_usuario} <span className="highlight">ganaste</span> el <span className="highlight">un descuento en tus mensualidades</span></h4>
                                : null}
                        <div className="ticket-container">
                            <img src="./images/assets/cupon.webp" alt="cupon" className="img-ticket" />
                            {tipo_premio === 4?
                            <div className="ticket-text">{premio[0].nombre}, {premio[1].nombre}</div>
                        :<div className="ticket-text">{premio[0].nombre}</div>}
                        </div>
                        <div className="code-container">
                            <p className="subtitle mt-4">Copia el código para canjearlo con tu tutor de clase</p>
                            <div className='button-container-copy'>
                                <div className="code">{cupon}</div>
                                <button onClick={handleCopy} className="copy-button">
                                    <FontAwesomeIcon icon={faCopy} />
                                </button>
                            </div>

                        </div>

                    </div>
                    :
                    < Spinner animation="border" />
                }
        </Layout >
    );
};

export default Cupon;