import React from 'react';
import SesionItem from './SesionItem';

const ProximasSesiones = ({sesiones}) => {
    return (
        sesiones?.length>0 ? sesiones.map((sesion,i)=>(
            <SesionItem datos={sesion} key={i}  />
        )) : 'No hay proximas sesiones'
 );
};

export default ProximasSesiones;