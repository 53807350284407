import React from 'react';
import './reviewButton.scss';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
const ReviewButton = ({ onClick, label }) => {
    const history = useHistory();

    const handleClick = (e) => {
        e.preventDefault();
        history.push('/review'); // Cambia '/ruta-destino' por la ruta a la que quieres redirigir
    };
    return (
        <a href="#" className="floating-review-button" onClick={handleClick} rel="noopener noreferrer">
            <img src="/images/icon-review.png" alt="Review" className="review-icon" />
            <span className="review-text">¡Valóranos y gana 30 puntos!</span>
        </a>
    );
};

export default ReviewButton;