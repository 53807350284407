import {
    ruletaTypes
  } from "./types";

import authAxios from "../config/authAxios";
import Swal from "sweetalert2";


export const guardarPremioRuleta = (datos) => async dispatch => {
    try {
     dispatch({
        type: ruletaTypes.OBTENER_CUPON,
    });
    const rpta = await authAxios.post(`/admin/guardarPremioRuleta`,datos)

    if(rpta.data.cupon){
        dispatch({
            type: ruletaTypes.OBTENER_CUPON_EXITO,
            payload: { cupon: rpta.data.cupon }
        });
    }

  } catch (error) {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: error.response.data.error,
    });
    dispatch({
      type: ruletaTypes.OBTENER_CUPON_ERROR,
    });
  }
}

export const verificarIntentosRuleta = (datos) => async dispatch => {


  try {
  const rpta = await authAxios.post(`/admin/verificarIntentosRuleta`,datos)
  return rpta.data.estado

} catch (error) {
  console.log(error)
  Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: error.response.data.error,
  });
}
}