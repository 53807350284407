import React from "react";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as moment from "moment";
import "./SesionItem.scss";

const SesionItem = ({ datos }) => {

	const a = moment(datos.fecha_sesion).format("YYYY-MM-DD");
	const b = moment().format("YYYY-MM-DD");
	const disabled = new Date(a) - new Date(b) !== 0 ? "disabled" : "";

	return (
		<div className="row py-4 border-bottom sesions-container">
			<div className="col-12 d-md-none d-inline">
				<span className="class-type">Clase en vivo</span>
			</div>
			<div className="col-5 col-md-2 calendar-container d-flex align-items-center">
				<img
					src="images/icons/calendar-icon.png"
					alt="calendar"
					className=" calendar-icon"
				/>
				<span>
					{" "}
					{datos.fecha_sesion}{" "}
				</span>
			</div>
			<div className="col-4 col-md-8 class-details">
				<span className="mr-md-5 class-type d-none d-md-inline">Clase en vivo</span>
				<span className="ml-1 ml-md-5 class-description">{datos.nombre_sesion}</span>
			</div>
			<div className="col-3 col-md-2 sesions-actions d-flex align-items-center">
				<a
					className={`btn btn-primary ${disabled}`}
					href={datos.ruta_archivo}
					target="_blank"
					rel="noopener noreferrer"
				>
					Ir a la clase
				</a>
			</div>
		</div>
	);
};

export default SesionItem;
