import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Layout from '../Layout/Layout';
import './premio.css';
import Swal from 'sweetalert2';
import { listarProyectos, obtenerProyectosActivos, obtenerSesionesActivasRuleta } from '../../actions/cursosActions';
import { guardarPremioRuleta } from '../../actions/ruletaActions';
import Cupon from './cupon';
import { Spinner } from 'react-bootstrap';
const Premio = (premio) => {


    const { nombre, angulo, detalle, tipo } = premio.premio;
    const { usuario } = useSelector(state => state.usuario);

    const [selectedCourses, setSelectedCourses] = useState([]);
    const [confirmedCourses, setConfirmedCourses] = useState([]);
    const { proyectosActivos } = useSelector((state) => state.cursos);
    const { cursosActivosRuleta } = useSelector(state => state.cursos);
    const { cupon, loading } = useSelector(state => state.cursos);
    const [opciones, setOpciones] = useState({});
    const [proyecto, setProyecto] = useState({});
    const dispatch = useDispatch();


    // Lista de opciones de curso
    const courses = [
        'Curso Intensivo para SUNAFIL - Noviembre 2024',
        'Curso Intensivo CAS SUNAT 2024',
        'Curso en Sistema Integrado de Registros Electrónicos (SIRE) SUNAT 2024',
        'Curso Intensivo para SUNAFIL',
        'Curso Especializado en Tributación 2024',
        'Curso Avanzado de Gestión Pública'
    ];

    // Función para manejar la selección de un curso
    const handleCourseSelect = (id, nombre) => {
        const opcionSelected = { nombre, id };
        if (tipo === 4) {
            if (selectedCourses.some(course => course.id === id)) {
                setSelectedCourses(selectedCourses.filter(course => course.id !== id));
            } else if (selectedCourses.length < 2) {
                setSelectedCourses([...selectedCourses, opcionSelected]);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Solo puedes seleccionar hasta 2 cursos',
                });
            }
        } else {
            setProyecto(opcionSelected);
            setSelectedCourses([opcionSelected]);
        }
    };
    // Función para confirmar la selección del curso
    const handleConfirmSelection = () => {
        if (selectedCourses.length > 0) {
            setConfirmedCourses(selectedCourses);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Debes seleccionar un curso para continuar',
            });
        }
    };

    useEffect(() => {
        dispatch(obtenerProyectosActivos(usuario.dni));
        dispatch(obtenerSesionesActivasRuleta(usuario.dni));
    }, []);

    useEffect(() => {
        if (tipo === 1) {
            setOpciones(proyectosActivos)
        } else if ((tipo === 2 || tipo === 4)) {
            setOpciones(cursosActivosRuleta)
        }
    }, [tipo, proyectosActivos, cursosActivosRuleta]);


    const enviarDatos = () => {

        const datos = {
            premio: nombre,
            tipo_premio: tipo,
            dni: usuario.dni,
            cursos: confirmedCourses.map(course => course.id),
        };


        dispatch(guardarPremioRuleta(datos));


    };

    useEffect(() => {
        if (confirmedCourses.length > 0) {
            enviarDatos();
        }
    }, [confirmedCourses]);
    


    return (
        <Layout>
            <div className='premio-ruleta-container'>
                {!cupon ?

                    <div className="container-selector">
                        {(tipo === 2 || tipo === 4) ?
                            <p className="message">
                                Felicidades, {usuario.nombre_usuario}  <span className="highlight">ganaste</span> el acceso a un curso en vivo
                            </p> : tipo === 1 ?
                                <p className="message">
                                    Felicidades, {usuario.nombre_usuario} <span className="highlight">ganaste</span> un descuento en tus mensualidades
                                </p> : null}

                        {tipo === 2 || tipo === 4 ?
                            <p className="subtext">Selecciona el curso que prefieras</p>
                            : tipo === 1 ?
                                <p className="subtext">Selecciona el producto que prefieras</p>
                                : null}
                                
                        <div className="course-options">

                            {tipo === 1 && Object.values(opciones).map((proyecto) => (
                                <button
                                    key={proyecto.id_proyecto}
                                    className={`course-option ${selectedCourses.some(course => course.id === proyecto.id_proyecto) ? 'selected' : ''}`}
                                    onClick={() => handleCourseSelect(proyecto.id_proyecto, proyecto.nombre_proyecto)}
                                >
                                    {proyecto.nombre_proyecto}
                                </button>
                            ))}
                            {(tipo === 2 || tipo === 4) && Object.values(opciones).map((sesion) => (
                                <button
                                    key={sesion.id_sesion}
                                    className={`course-option ${selectedCourses.some(course => course.id === sesion.id_sesion) ? 'selected' : ''}`}
                                    onClick={() => handleCourseSelect(sesion.id_sesion, sesion.nombre_sesion)}
                                >
                                    {sesion.nombre_sesion}
                                </button>
                            ))}
                        </div>
                        <button className="confirm-button" onClick={handleConfirmSelection}>
                            Confirmar
                        </button>
                        <div className="selected-course">
                            {(tipo === 2 || tipo === 4) ?
                                <p>Curso Seleccionado:</p>
                                : tipo === 1 ? <p>Producto Seleccionado:</p> : null}
                            <p>{selectedCourses.map(course => course.nombre).join(', ')}</p>
                        </div>
                    </div>
                    : loading ? <div className="text-center mt-5">
                        {/* Muestra el spinner mientras se carga */}
                        <Spinner animation="border" role="status">
                        </Spinner>
                    </div> :
                        <>
                            <Cupon
                                premio={confirmedCourses}
                                tipo_premio={tipo}
                            />
                        </>
                }
            </div>
        </Layout>
    );
};

export default Premio;